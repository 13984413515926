import { BaseModel, IBaseModel } from './Base.model';

export enum LinkTypes {
    URL = 'url',
    STORY = 'story',
}

export interface CmsLink {
    link: Link;
    label: string;
}

export interface ILink extends IBaseModel {
    cached_url: string
    fieldtype: string
    id: string
    linktype: LinkTypes
    url: string
    target: string
    email: string
}

export class Link extends BaseModel {
    public cached_url?: string;

    public fieldtype?: string;

    public id?: string;

    public linktype?: LinkTypes;

    public url?: string;

    public target?: string;

    public email?: string;

    /**
     * Constructor
     * @param {String} id id of the object
     */
    constructor(link: ILink) {
        super(link);

        this.cached_url = link.cached_url;
        this.fieldtype = link.fieldtype;
        this.id = link.id;
        this.linktype = link.linktype;
        this.url = link.url;
        this.target = link.target;
        this.email = link.email;
    }
}
