
















































































import {
  computed,
defineComponent, PropType, Ref, toRefs, useContext, useRouter,
} from '@nuxtjs/composition-api';
import BKInterruptionCard from '~/components/BK/BKInterruptionCard.vue';
import BKProductCard from '~/components/BK/BKProductCard.vue';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import { useImage, useUiState } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import { useProductsWithCommonProductCardProps } from './useProductsWithCommonCardProps';

interface InterruptionCard {
  headline: string;
  paragraphs?: string[];
  callToActionText?: string;
  onClickFallback?: () => void;
  backgroundImage?: string;
}

export default defineComponent({
  name: 'CategoryProductGrid',
  components: {
    BKProductCard,
    SkeletonLoader,
    BKInterruptionCard,
},
  props: {
    products: {
      type: Array as PropType<Product[]>,
      required: true,
    },
    pricesLoaded: Boolean,
    loading: Boolean,
    filterOpen: {
      type: Boolean,
      default: false,
    },
    current: {
      type: Number,
      default: 1,
    },
    showFramesizeCard: {
      type: Boolean,
      default: false,
    },
    isBikeCategory: {
      type: Boolean,
      default: true,
    },
    activeSizeFilterValues: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  setup(props) {
    const {
      imageSizes: { productCard: imageSize },
    } = useImage();
    const { products } = toRefs(props);
    const router = useRouter();
    const { productsWithCommonProductCardProps } = useProductsWithCommonProductCardProps(products);
    const { toggleBikeFinderModal, toggleFramesizeModal } = useUiState();
    const { app: { i18n } } = useContext();

    const doToggleFramesizeModal = () => {
      toggleFramesizeModal();
    };

    const doToggleBikefinderModal = () => {
      toggleBikeFinderModal();
    };

    const openExpertAdvice = async () => {
      await router.push({
        path: '/fachgeschaeft/kaufberatung',
      });
    };

    // for some use cases we need to split the products in two halves to show an interruption card in between
    const half = computed(() => Math.ceil((productsWithCommonProductCardProps.value?.length ?? 0) / 2));
    const firstHalf = computed(() => productsWithCommonProductCardProps.value?.slice(0, half.value));
    const secondHalf = computed(() => productsWithCommonProductCardProps.value?.slice(half.value));

    const firstPageWithResults = computed(() => props.current === 1 && productsWithCommonProductCardProps.value?.length > 0);

    const secondCardContent: Ref<InterruptionCard | null> = computed(() => {
      // show first card only if we are on the first page and there are products
      if (!firstPageWithResults.value || !props.isBikeCategory) {
        return null;
      }

      return props.showFramesizeCard
        ? {
          headline: `${i18n.t('Your individual framesize')}`,
          paragraphs: [
            `${i18n.t('No bike is like the other')}`,
            `${i18n.t('This is the way we calculate your framesize')}`,
          ],
          callToActionText: `${i18n.t('Calculate individual framesize')}`,
          onClickFallback: doToggleFramesizeModal,
          backgroundImage: '/interruptionCard/framesize-background.png',
        }
        : null;
    });

    const bikefinderCardContent: Ref<InterruptionCard | null> = computed(() => {
      // show bikefinder card only if we are on the first page and there are products
      if (!firstPageWithResults.value || !props.isBikeCategory) {
        return null;
      }

      return {
        headline: `${i18n.t('Bikefinder interruption card headline')}`,
        paragraphs: [
          `${i18n.t('Bikefinder interruption card text')}`,
        ],
        callToActionText: `${i18n.t('Bikefinder interruption card button text')}`,
        onClickFallback: doToggleBikefinderModal,
        backgroundImage: '/interruptionCard/bikefinder-background.png',
      };
    });

    const expertAdviceCardContent: Ref<InterruptionCard | null> = computed(() => {
      // show expert advice card only if we are on the first page and there are products
      if (!firstPageWithResults.value) {
        return null;
      }

      return props.isBikeCategory
      ? {
          headline: `${i18n.t('Expert advice interruption card headline')}`,
          paragraphs: [
            `${i18n.t('Expert advice interruption card text first line')}`,
            `${i18n.t('Expert advice interruption card text second line')}`,
          ],
          callToActionText: `${i18n.t('Expert advice interruption card button text')}`,
          onClickFallback: openExpertAdvice,
          backgroundImage: '/interruptionCard/expert-advice-background.png',
        }
      : {
          headline: `${i18n.t('Theres more to see!')}`,
          paragraphs: [
            `${i18n.t('Although we have shown all of our bikes online, we have only shown part of the rest of our range.')}`,
            `${i18n.t('You can find the full selection of more than 3,000 different items in our shop on Triester Straße.')}`,
            `${i18n.t('Interruption Clickn collect')}`,
          ],
          backgroundImage: '/interruptionCard/component-background.png',
        };
    });

    return {
      imageSize,
      doToggleFramesizeModal,
      secondCardContent,
      bikefinderCardContent,
      expertAdviceCardContent,
      firstHalf,
      secondHalf,
    };
  },
});
