



































import {
defineComponent, onMounted,
} from '@nuxtjs/composition-api';
import { SfModal } from '@storefront-ui/vue';
import BKButton from '../../../../../../components/BK/BKButton.vue';

export default defineComponent({
  name: 'AddBikeToCartModal',
  components: {
    SfModal,
    BKButton,
  },
  setup() {
    onMounted(() => {
      const modalButton = document.querySelectorAll('.sf-modal__close')[0] as HTMLElement;
      modalButton.classList.remove('desktop-only');
    });

    return {};
  },
});
