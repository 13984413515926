




































import Vue from 'vue';
import { defineComponent } from '@nuxtjs/composition-api';
import { ISbRichtext } from 'storyblok-js-client';
import RichText from './helpers/RichText.vue';
import { isRichTextEmpty } from './helpers/renderRichText';

  interface IRichTextElement extends HTMLElement {
    richtext: string
  }

  enum TagType {
    H1 = 'h1',
    H2 = 'h2',
    H3 = 'h3',
    H4 = 'h4',
  }

  enum BackgroundColor {
    LightGray = 'bg-zinc-100',
    White = 'bg-white',
  }

  export default defineComponent({
    name: 'HeadlineText',
    components: { RichText },
    props: {
      headline: { type: Object, default: () => {} },
      text: { type: Object, default: () => {} },
      backgroundColor: { type: String, default: BackgroundColor.LightGray },
      onlyApplyStyling: { type: Boolean, default: false },
    },
    setup(props) {
      const initialHeadlineTag: string = TagType.H1;

      return {
        headlineTag: initialHeadlineTag,
        isRichTextEmpty,
        richText: props.text as ISbRichtext,
        richHeadline: props.headline as ISbRichtext,
      };
    },
    mounted() {
      const headlineRichTextElement: IRichTextElement = this.$refs.headlineRichText as IRichTextElement;

      if (headlineRichTextElement) {
        const headlineRichText : string = headlineRichTextElement?.richtext;

        // the first occurrence of a headline determines the columns
        this.headlineTag = headlineRichText?.slice(
          headlineRichText.indexOf('<') + 1,
          headlineRichText.indexOf('>'),
        );

        // we can't use normal Vue component update here as we need to have the correct rich text logic in place
        // and afterwards update the computed html if necessary
        if (this.isOnlyApplyStyling()) {
          const updatedHeadlineRichText = headlineRichText
            .replace(/<h1>/g, '<p class="h1">')
            .replace(/<h2>/g, '<p class="h2">')
            .replace(/<h3>/g, '<p class="h3">')
            .replace(/<h4>/g, '<p class="h4">')
            .replace(/<\/h1>|<\/h2>|<\/h3>|<\/h4>/g, '</p>');
          Vue.set(headlineRichTextElement, 'richtext', updatedHeadlineRichText);
        }
      }
    },
    methods: {
      headlineClass(headlineTag: string): string {
        let classNames = '';
        switch (headlineTag) {
          case TagType.H1:
            classNames = 'md:col-end-9';
            break;
          case TagType.H2:
            classNames = 'md:col-end-6';
            break;
          case TagType.H3:
            classNames = 'md:col-end-4';
            break;
          case TagType.H4:
            classNames = 'md:col-end-4';
            break;
          default:
            classNames = 'md:col-end-4';
        }

        return `${classNames} ${!isRichTextEmpty(this.text as ISbRichtext) ? 'md:mb-12 mb-6' : 'mb-0'} ${headlineTag}`;
      },
      isOnlyApplyStyling(): boolean {
        return this.onlyApplyStyling;
      },
    },

  });
