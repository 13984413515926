




import {
  computed,
  defineComponent,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import { ISbRichtext } from 'storyblok-js-client';
import { renderRichText } from './renderRichText';

export default defineComponent({
  name: 'RichText',
  props: {
    content: { type: Object, default: () => {} },
  },
  setup(props) {
    const text = ref('');

    // for some use cases like headline text we need to be able to update the richtext string
    // after performing some regex on the actual html code
    const richtext = computed(
      {
        get: () => text.value,
        set: (val: string) => {
          text.value = val;
        },
      },
    );

    watch(() => props, (newProps) => {
      text.value = renderRichText(newProps.content as ISbRichtext);
    }, { immediate: true, deep: true });

return { richtext };
  },
});
