









































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { Link } from '~/cms/models/Link.model';
import BKLink from '~/components/BK/BKLink.vue';
import { optimizeImage } from '~/helpers/image';
import RichText from '../../helpers/RichText.vue';
import { Image } from '../../models/Image.model';

  export default defineComponent({
    name: 'Card',
    components: {
      RichText,
      BKLink,
    },
    props: {
      image: { type: Object, default: () => {} },
      headline: { type: String, default: '' },
      text: { type: Object, default: () => {} },
      link: { type: Object, default: () => {} },
      onlyLinkImage: { type: Boolean, default: false },
    },
    setup(props) {
      const cardLink: Link = props.link?.link as Link;
      const cardImage: Image = props.image.url as Image;
      const hasText: boolean = !!props.text.content[0].content?.length;
      const imageProp: Image = props.image.url as Image;
      const optimizedImage: string = imageProp.filename;
      const showCardLink = computed(() => cardLink && !props.onlyLinkImage);

      return {
        optimizedImage,
        imageProp,
        cardImage,
        cardLink,
        hasText,
        showCardLink,
      };
    },
    mounted() {
      this.optimizedImage = optimizeImage(this.imageProp, '500x300');
    },
  });
