










import { defineComponent } from '@nuxtjs/composition-api';
import RichText from '~/cms/helpers/RichText.vue';

export default defineComponent({
  name: 'KeyFact',
  components: {
    RichText,
  },
  props: {
    headline: { type: String, default: '' },
    text: { type: Object, default: () => {} },
  },
});
