








































































































import {
SfBadge, SfButton, SfIcon,
} from '@storefront-ui/vue';

import {
computed, defineComponent, onMounted,
ref, useContext, useRoute, useRouter,
} from '@nuxtjs/composition-api';
import { accountIcon, cartIcon, searchIcon } from '~/cms/helpers/customIcons';
import BKHeader from '~/components/BK/Header/BKHeader.vue';
import HeaderNavigation from '~/components/Header/Navigation/HeaderNavigation.vue';
import HeaderLogo from '~/components/HeaderLogo.vue';
import { useUiHelpers, useUiState } from '~/composables';
import { searchTermQueryParameter } from '~/helpers/routes';
import type { ProductInterface } from '~/modules/GraphQL/types';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import Vue from 'vue';
import { isBreakpointXl } from '../cms/helpers/breakpoints';

export enum FontColor {
  Black = '#18181B',
  White = '#FAFAFA',
}

enum BackgroundColor {
  LightGray = 'light-gray',
  Transparent = 'transparent',
}

export default defineComponent({
  components: {
    HeaderNavigation,
    BKHeader,
    HeaderLogo,
    SfIcon,
    SfButton,
    SfBadge,
    SearchBar: () => import(/* webpackPrefetch: true */ '~/components/Header/SearchBar/SearchBar.vue'),
  },
  props: {
    navigation: { type: Object, default: () => {} },
  },
  setup(props) {
    const router = useRouter();
    const route = useRoute();
    const { app } = useContext();
    const { toggleCartSidebar, toggleLoginModal } = useUiState();
    const { setTermForUrl } = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();

    const ROUTE_SEARCH = 'search';

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const isCurrentlyInSearch = computed(() => route.value.name === ROUTE_SEARCH);

    const fontColor = computed(() => (props.navigation?.fontColor === 'white' ? FontColor.White : FontColor.Black));
    const backgroundColor = computed(() => props.navigation?.backgroundColor ?? BackgroundColor.LightGray);
    const menuItems = computed(() => props.navigation?.menuItems ?? []);
    const isOverflowed = ref(false);
    const cartTotalItems = computed(() => cart.value?.total_quantity ?? 0);

    const isBreakpointXL = false;

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
      }
    });

    return {
      accountIcon,
      searchIcon,
      cartIcon,
      cartTotalItems,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      menuItems,
      fontColor,
      backgroundColor,
      BackgroundColor,
      FontColor,
      isOverflowed,
      router,
      route,
      isCurrentlyInSearch,
      ROUTE_SEARCH,
      isBreakpointXl,
      isBreakpointXL,
    };
  },
  mounted() {
    this.isBreakpointXL = this.isBreakpointXl(window);
  },
  updated() {
    this.isBreakpointXL = this.isBreakpointXl(window);
  },
  methods: {
    searchProducts(term: string) {
      this.router.push({
        name: this.ROUTE_SEARCH,
        query: {
          [searchTermQueryParameter]: term,
        },
      });
    },
    toggleSearchBar(isOpen: boolean) {
      this.isSearchOpen = isOpen;
      this.setCursorActive();
    },
    setCursorActive() {
      // This is a hard fix, because I had to wait for the element to be visible on the DOM, just to aply the focus on it
      setTimeout(() => {
        const searchBarRef = this.$refs.searchBar as Vue;
        if (searchBarRef) {
          (searchBarRef.$el.querySelector('input') as HTMLInputElement).focus();
        }
      }, 100);
    },
  },
});
