// we need to remove all non-numeric characters from the price string, but still need to keep the comma
// price is coming as 1.149,00 €
// for parseFloat to work, we need to replace the comma with a dot as well - otherwise it will just ignore the parts after the comma
// leading to incorrect calculations of the discount
const formatPrice = (price: string) => price.replace(/[^\d,-]/g, '').replace(/,/g, '.');

export const discount = (regularPrice: number | string | null, discountPrice: number | string | null) => {
  if (!regularPrice || !discountPrice) {
    return null;
  }

  const regular = typeof regularPrice === 'string' ? Number.parseFloat(formatPrice(regularPrice)) : regularPrice;
  const special = typeof discountPrice === 'string' ? Number.parseFloat(formatPrice(discountPrice)) : discountPrice;

  return Math.round(((regular - special) / regular) * 100);
};
