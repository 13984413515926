
























import { defineComponent } from '@nuxtjs/composition-api';
import BKLink from '~/components/BK/BKLink.vue';
import { Link } from '../models/Link.model';
import ProductPreviewCardContent from './components/ProductPreviewCardContent.vue';

export default defineComponent({
  name: 'ProductPreviewCard',
  components: {
    BKLink,
    ProductPreviewCardContent,
  },
  props: {
    caption: { type: String, default: '' },
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
    link: { type: Object, default: () => {} },
    wrapperClass: { type: String, default: '' },
  },
  setup(props) {
    const linkProp : Link = props.link as Link;
    const hasLinkUrl: boolean = linkProp && Boolean(linkProp.url || (linkProp.cached_url || linkProp.id));

    return {
      hasLinkUrl,
      linkProp,
    };
  },
});
