















import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductPreviewCard',
  props: {
    caption: { type: String, default: '' },
    headline: { type: String, default: '' },
    image: { type: Object, default: () => {} },
  },
});
