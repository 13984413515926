


























































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfIcon } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  name: 'BKInterruptionCard',
  components: { SfIcon },
  props: {
    filterOpen: {
      type: Boolean,
      default: false,
    },
    headline: {
      type: String,
      default: '',
    },
    paragraphs: {
      type: Array,
      default: () => [],
    },
    callToActionText: {
      type: String,
      default: '',
    },
    backgroundImage: {
      type: String,
      default: '/interruptionCard/framesize-background.png',
    },
    hasLink: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const isFilterBarOpen = computed(() => props.filterOpen);

    return { isFilterBarOpen, addBasePath };
  },
});
