








































import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { isExternalLink } from '~/cms/helpers/urls';
import { Link, LinkTypes } from '~/cms/models/Link.model';

export default defineComponent({
  name: 'BKLink',
  props: {
    link: { type: Object as PropType<Link>, default: () => {} },
    linkClass: { type: String, default: '' },
    label: { type: String, default: '' },
    wrapperClass: { type: String, default: '' },
  },
  computed: {
    linkUrl(): string {
      const { url, cached_url: cachedUrl } = this.link;
      const linkUrl = url || cachedUrl;
      if (this.isExternal) {
        return linkUrl;
      }

      // leading slash is important for all internal links otherwise navigation is messed up in nested path scenarios
      return this.link.linktype === LinkTypes.URL ? linkUrl : `/${linkUrl}`;
    },
    isExternal(): boolean {
      return isExternalLink(this.link);
    },
    target(): string {
      return this.link.target || '_self';
    },
  },
  methods: {
    shouldRenderLabel(): boolean {
      return this.label?.length > 0;
    },
  },
});
